<template>
  <BaseFrontdoor>
    <BaseContainer fluid class="max-w-1024 text-center">
      <BaseIcon icon="pi pi-check-square" class="text-success mt-8" size="10" />
      <BaseHeadline size="1" fake="2" class="mt-6">
        {{ $t("uc.checkout.success.title") }}
      </BaseHeadline>
      <BaseParagraph>
        {{ $t("uc.checkout.success.description") }}
      </BaseParagraph>
      <BaseButton label="uc.checkout.success.button" class="mt-1" @click="pushToCourseList" />
    </BaseContainer>
  </BaseFrontdoor>
</template>

<script>
export default {
  name: "CartCheckoutSuccess",
  methods: {
    pushToCourseList() {
      try {
        this.$router.push({
          name: "MyCourses",
        });
      } catch {
        return;
      }
    },
  },
};
</script>
